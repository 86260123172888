<template>
  <div>
    <el-row class="dash-board" :data="dataList" style="padding-left: 10px;"  v-if="showPrise">
        <el-button type="primary" size="mini" @click="AllClass()">返回</el-button>
        <el-button @click="random(classId)" size="mini" type="success" >选中随机分组</el-button>
        <el-button type="warning" size="mini" @click="reset(classId)" v-permission="['Exams:batch']">选中批量分组</el-button>
        <el-button type="primary" size="mini" @click="GroupAll()">全部随机分组</el-button>
        <p style="margin: auto 10px;">分组名称:</p>
        <el-select v-model="groupId" @change="groupChange" filterable clearable >
          <el-option style="width: 200px" v-for="(item,index) in selectDutyOrGroup" :key="index" :label="item.value" :value="item.id"></el-option>
        </el-select>
        <p style="margin: auto 10px;">学员名称:</p>
        <el-input  autocomplete="off" value="" style="width: 300px" v-model="stuName"></el-input>
        <el-button type="primary" size="mini" @click="getStuList()">搜索</el-button>
      <el-dialog
        :title="titl" :visible.sync="fenpeires"
      >
        <p class="cheng">分组名称:</p>
        <el-select class="xiala" v-model="query.gDId" filterable clearable>
          <el-option style="width: 200px"
                     v-for="(item,index) in selectDutyOrGroup"
                     :key="index"
                     :label="item.value"
                     :value="item.id">
          </el-option>
        </el-select>
        <div>
          <el-button type="primary" @click="fenpeires = false">取消</el-button>
          <el-button type="primary" @click="assignGroupp('')">确定</el-button>
        </div>
      </el-dialog>
      <template slot-scope="scope">
        <span>{{ scope.row.className }}</span>
      </template>
    </el-row>
    <el-row class="dash-board" style="padding-left: 10px;" v-if="!showPrise">
      <el-form @submit.native.prevent>
        <el-form-item label="搜索班级" label-width="68px">
          <el-input  autocomplete="off" value="" style="width: 300px" v-model="className"></el-input>
          <el-button type="primary" @click="getAllClass()">搜索</el-button>
        </el-form-item>
      </el-form>
    </el-row>
    <el-dialog :title = "ttle" :visible.sync="classone">
      <p class="dutyto">职务名称:</p>
      <el-input class="dutyss" v-model="dutyName"></el-input>
      <p class="dutytp">职务描述:</p>
      <el-input class="dutysss" type="textarea" rows="4" v-model="dutyRemark"></el-input>
      <div class="yessd">
        <el-button @click="classone = false"  type="primary">取消</el-button>
        <el-button @click="dutyComm"  type="primary">确定</el-button>
      </div>
    </el-dialog>
    <el-dialog :title="titlees" :visible.sync="Classname">
      <el-form>
        <el-form-item  label="搜索班级" required>
          <el-input  autocomplete="off" value="" style="width: 300px" v-model="className"></el-input>
          <el-button type="primary" @click="getAllClass()">搜索</el-button>
        </el-form-item>
        <el-table
          :data="dataList"
        >
          <el-table-column
            label="班级名称"
            width="200px">
            <template slot-scope="scope">
              <span style="margin-left: 10px" class="spani">{{ scope.row.className }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="班级人数"
            width="100px">
            <template slot-scope="scope">
              <span style="margin-left: 10px">{{ scope.row.classNumber }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            width="300px">
            <template slot-scope="scope">
              <el-button type="primary" v-if="scope.row.applyNumber > 0 " size="mini" @click="GroupAll(scope.row.classId)">全部随机分组</el-button>
              <el-button type="primary"  size="mini" @click="classGet(scope.row.classId,scope.row.className)">选择班级</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
    </el-dialog>
    <el-dialog :visible.sync="addition">
      <el-form :model="groups">
        <el-form-item>
          <!-- <p class="ming">分组数量</p> -->
          <p>输入你想生成的小组数量</p>
          <!-- <el-input  v-model="groups.number" class="minginp" value="" style="width: 200px"></el-input> -->
          <el-input-number v-model="groups.number" :min="1" :max="99" :precision="0" style="width: 200px;"></el-input-number>
        </el-form-item>
        <el-button @click="addition = false">取消</el-button>
        <el-button @click="groupCommitf()">确定</el-button>
      </el-form>
    </el-dialog>
    <el-row class="bg">
      <div>
        <el-table
         v-if="!showPrise"
          :data="dataList">
          <el-table-column
            label="班级名称" prop="className"/>
          <el-table-column
            label="班级人数"
            align="center"
           >
            <template slot-scope="scope">
              <span style="margin-left: 10px">{{ scope.row.classNumber }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="班级分组"
            align="center"
             prop="groupNumber"/>
          <el-table-column
            label="操作"
            width="330px" align="center">
            <template slot-scope="scope">
              <el-button type="success" size="mini" @click="handleCurrentClass(scope.row)">查看学员列表</el-button>
              <el-button type="warning" size="mini" v-if="scope.row.groupNumber>0" @click="appends(scope.row.classId)">修改分组</el-button>
              <el-button type="primary" size="mini" v-else @click="appends(scope.row.classId)">添加分组</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-row v-if="!showPrise">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageNumClass"
            :page-size="pageSizeClass"
            layout="total,sizes,  prev, pager, next, jumper"
            :total="totalClass"
            class="pag"
          />
        </el-row>
      </div>
      <div  v-if="showPrise">
        <el-table
          :data="tableData"
          style="width: 100%;"
          @selection-change="handleSelectionChange"
          tooltip-effect="dark">
          <el-table-column
            type="selection"
          >
          </el-table-column>
          <el-table-column
            label="序号"
            align="center"
            width="90">
            <template slot-scope="scope">
              <span>{{ scope.row.stuId }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="姓名"
            align="center"
            width="90">
            <template slot-scope="scope">
              <div slot="reference" class="name-wrapper">
                <span>{{ scope.row.stuName }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="性别"
            align="center"
            width="100">
            <template slot-scope="scope">
              <span  v-if="scope.row.sex==0 ">男</span>
              <span  v-if="scope.row.sex==1 ">女</span>
            </template>
          </el-table-column>
           <el-table-column
            label="班级"
            align="center"
            width="300">
            <template slot-scope="scope">
             <span>{{ scope.row.className }}</span>
            </template>
          </el-table-column>
           <el-table-column
            label="单位名称"
            align="center"
            width="220">
            <template slot-scope="scope">
              <span>{{ scope.row.depName }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="职务"
            align="center"
            width="220">
            <template slot-scope="scope">
              <span>{{ scope.row.stuDuty }}</span>
            </template>
          </el-table-column>
          <el-table-column label="联系电话" align="center" width="160">
            <template slot-scope="scope">
              <span>{{ scope.row.stuPhone }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="分组"
            align="center"
            width="100">
            <template slot-scope="scope">
              <el-tag type="success">{{ scope.row.groupName }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            label="职务"
            align="center"
            width="100">
            <template slot-scope="scope">
              <el-tag>{{ scope.row.classPosition }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            align="center"
            width="300px">
            <template slot-scope="scope">
              <el-button
                type="primary"
                size="mini"
                v-permission="['Exams:job']"
                @click="open(scope.row)">分配职务
              </el-button>
              <el-button
                type="success"
                size="mini"
                v-permission="['Exams:group']"
                @click="opes(scope.row)">分配分组
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-row style="margin-top:20px">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageNum"
            :page-size="pageSize"
            layout="total,sizes,  prev, pager, next, jumper"
            :total="total"
            class="pag"
          >
          </el-pagination>
        </el-row>
      </div>
    </el-row>
    <el-dialog
      :title="titl" :visible.sync="fenpei"
    >
      <p class="cheng">分组名称:</p>
      <el-select class="xiala" v-model="query.gDId" filterable clearable >
        <el-option style="width: 200px" v-for="(item,index) in selectDutyOrGroup" :key="index" :label="item.value" :value="item.id"></el-option>
      </el-select>
      <div>
        <el-button type="primary" @click="fenpei = false">取消</el-button>
        <el-button type="primary" @click="assignGroup('')">确定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      :title="titleste" :visible.sync="zhiwu"
    >
      <p class="cheng">职务:</p>
      <el-select class="xiala" v-model="query.cDId" filterable clearable>
        <el-option lable="" style="width: 200px"
                   v-for="(item,index) in data"
                   :key="index"
                   :label="item.label"
                   :value="item.id">
        </el-option>
      </el-select>
      <div>
        <el-button type="primary" @click="zhiwu = false">取消</el-button>
        <el-button type="primary" @click="quedings()">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { findStuToGroupInfo, groupCommitf, batchAssign, randomAssortment, randomAssortmentAll } from '@/api/admin.js'
  import { findClassInfoToGroup, addProduceGroup } from '@/api/user.js'
  import { getGroupList, assignGroup } from '@/api/group.js' // , assignGroupInfo
  import { Classification } from '@/api/training.js'
  export default {
    name: 'Dashboard',
    data () {
      return {
        fenpeires: false,
        loading: false,
        total: 0,
        totalClass: 0,
        pageSize: 10,
        pageSizeClass: 10,
        pageNum: 1,
        pageNumClass: 1,
        selectDutyOrGroup: [], // 分配分组或者职务时将下拉数据存储在这里
        // 下拉选中的班级
        selectClass: {},
        groups: {
          number: null,
          classId: ''
        },
        ttle: '添加职务',
        dutyName: '', // 职务名称
        dutyRemark: '', // 职务描述
        groupName: {
          id: ''
        }, // 分组名字
        groupId: '',
        classone: false,
        zhiwu: false,
        titleste: '安排职务',
        titl: '移入分组',
        ClassID: '',
        className: '',
        Classname: false,
        fenpei: false,
        stu: {},
        titlee: '创建分组',
        titlees: '选择班级',
        addition: false,
        classId: '', // 查询的班级id
        stuName: '', // 学生姓名
        dataList: [{
          id: ''
        }
        ],
        // 班级职务
        data: [],
        form: {},
        input: '',
        title: '添加用戶',
        dialogFormVisible: false,
        formLabelWidth: '100px',
        classNameStr: '',
        createGroupData: {},
        tableData: [{
          stuId: '',
          groupName: '',
          stuPhone: '',
          groupId: '',
          sex: '',
          stuDuty: '',
          stuName: '',
          classPosition: '',
          stuBirth: ''
        }],
        query: {},
        showPrise: false
      }
    },
    mounted () { // 钩子函数
      this.findStuToGroupInfo()// 初始化
      this.getAllClass() // 初始化班级
      this.list() // 初始化班级
    },
    methods: {
      GroupAll() {
        randomAssortmentAll(this.classId).then(resp => {
           if (resp.data.code === '200') {
             this.$notify({
                title: '成功',
                message: '全部分组成功。',
                type: 'success'
              })
              this.Classname = false
              this.findStuToGroupInfo()
           } else {
                  this.$notify({
                    title: '失败',
                    message: resp.data.msg,
                    type: 'error'
                  })
                }
        })
      },
      list() {
        const parenId = 150
        Classification(parenId).then(res => {
          if (res.data.code === '0') {
            this.data = res.data.data
          }
        })
      },
      // 随机分组
      random(classId) {
        if (this.multipleSelection) {
           this.classId = classId
            const groupIds = []
            this.multipleSelection.forEach(item => {
              groupIds.push(item.groupId)
            })
            randomAssortment(groupIds).then(resp => {
              if (resp.data.code === '200') {
                  this.$notify({
                    title: '成功',
                    message: '成功。',
                    type: 'success'
                  })
                  this.findStuToGroupInfo()
                } else {
                  this.$notify({
                    title: '失败',
                    message: resp.data.msg,
                    type: 'error'
                  })
                  this.findStuToGroupInfo()
                }
              })
        } else {
           this.$message({
               type: 'warning ',
               message: '请先勾选需要分组的学员'
            })
        }
      },
      handleSizeChange(e) {
        this.pageSize = e
        this.pageSizeClass = e
        this.findStuToGroupInfo()
      },
      handleCurrentChange(e) {
        this.pageNum = e
        this.pageNumClass = e
        this.getAllClass()
        this.findStuToGroupInfo()
      },
      classGet(ClassID, classNameStr) {
        this.classNameStr = classNameStr
        this.Classname = false
        this.classId = ClassID
        this.findStuToGroupInfo()
        this.showPrise = true
      },
      findStuToGroupInfo: function () {
        console.log(this.stuName)
        findStuToGroupInfo(this.classId, this.groupId, this.stuName, this.pageNum, this.pageSize).then(resp => {
          if (resp.data.code === '0') {
            this.total = resp.data.data.total // 接收返回的数据
            this.tableData = resp.data.data.rows // 接收返回的数据
            console.log(this.tableData)
          }
        })
      },
      // 获取全部班级列表
      getAllClass: function () {
        const query = {
          classId: this.classId,
          className: this.className,
          pageNum: this.pageNumClass,
          pageSize: this.pageSizeClass
        }
        console.log(query)
        findClassInfoToGroup(query).then(resp => {
          if (resp.data.code === '0') {
            this.dataList = resp.data.data.rows // 接收返回的数据
            console.log(this.dataList)
            this.totalClass = resp.data.data.total
          }
        })
      },
      // 返回按钮事件
      AllClass() {
        console.log(1)
        this.classId = ''
        this.pageNumClass = 1
        this.pageSizeClass = 10
        this.getAllClass()
        this.showPrise = false
      },
      // 查看学员列表事件
      handleCurrentClass(e) {
        console.log(e)
        this.classId = e.classId
        this.pageNum = 1
        this.findDutyOrGroup(this.classId, 0)
        this.findStuToGroupInfo()
        this.showPrise = true
      },
      // 学员名称搜索事件
      getStuList() {
        this.findStuToGroupInfo()
      },
      // 搜索栏---分组下拉选择事件
      groupChange(e) {
        console.log(e)
        this.findStuToGroupInfo()
      },
      appends(ClassID) {
        this.addition = true
        this.classId = ClassID
        console.log(ClassID)
      },
      duty(ClassID) {
        this.classId = ClassID
        this.classone = true
      },
      opes(row) { // 分配分组
        console.log(row)
        console.log(this.classId)
        this.query = {}
        this.fenpei = true
        // this.query.classId = row.classId
        this.query.classId = this.classId
        this.query.stuId = row.stuId
        // this.findDutyOrGroup(row.classId, 0)
        this.findDutyOrGroup(this.classId, 0)
      },
      open(row) {
        this.query = {}
        // this.query.classId = row.classId
        this.query.classId = this.classId
        this.query.stuId = row.stuId
        this.zhiwu = true
        // this.findDutyOrGroup(row.classId, 1)
        this.findDutyOrGroup(this.classId, 1)
        this.list()
      },
      assignGroup(tables) { // 分配组
        console.log(this.stuId)
        assignGroup(this.query).then(resp => {
          this[tables] = {}
          if (resp.data.code === '0') {
            this.$notify({
              title: '成功',
              message: '分配分组成功',
              type: 'success'
            })
            this.fenpei = false
            this.findStuToGroupInfo()
           // history.go(0) // 刷新当前页面
          } else {
            this.$notify({
              title: '失败',
              message: resp.data.msg,
              type: 'error'
            })
          }
        })
      },
      quedings(tables) { // 分配职务
        this.zhiwu = false
        console.log(this.stuId)
        assignGroup(this.query).then(resp => {
          this[tables] = {}
          if (resp.data.code === '0') {
            this.$notify({
              title: '成功',
              message: '分配分组成功',
              type: 'success'
            })
           this.zhiwu = false
           this.findStuToGroupInfo()
          } else {
            this.$notify({
              title: '失败',
              message: resp.data.msg,
              type: 'error'
            })
          }
        })
      },
      groupCommitf () { // 添加分组
        console.log(this.classId)
        this.groups.classId = this.classId
        addProduceGroup(this.groups).then(resp => {
          this.groups = {
            number: '',
            classId: ''
          }
          if (resp.data.code === '0') {
            this.$notify({
              title: '成功',
              message: '添加分组成功。',
              type: 'success'
            })
            this.classId = ''
            this.addition = false
            this.getAllClass()
          } else {
            this.$notify({
              title: '失败',
              message: resp.data.msg,
              type: 'error'
            })
            this.classone = false
          }
        })
      },
      assignGroupp() { // 批量分组
          this.multipleSelection.forEach(item => {
            item.id = item.groupId
          })
          batchAssign(this.multipleSelection, this.query.gDId).then(resp => {
            if (resp.data.code === '0') {
              this.$notify({
                title: '成功',
                message: '成功。',
                type: 'success'
              })
              this.fenpeires = false
              this.findStuToGroupInfo()
            } else {
              this.$notify({
                title: '失败',
                message: resp.data.msg,
                type: 'error'
              })
              this.fenpeires = false
              this.findStuToGroupInfo()
            }
          })
      },
      reset(ClassID) {
         if (this.multipleSelection) {
            this.classId = ClassID
            this.fenpeires = true
            this.findDutyOrGroup(this.classId, 0)
        } else {
           this.$message({
               type: 'warning ',
               message: '请先勾选需要分组的学员'
            })
        }
      },
      handleSelectionChange(respes) {
        console.log(respes)
        this.multipleSelection = respes
      },
      dutyComm () { // 添加职务
        const createDutyData = {
          classId: this.classId,
          groupName: this.dutyName,
          description: this.dutyRemark,
          type: this.type = 1
        }
        const qs = require('querystring')
        groupCommitf(qs.stringify(createDutyData)).then(resp => {
          console.log(resp)
          if (resp.data.code === '0') {
            this.$notify({
              title: '成功',
              message: '添加成功。',
              type: 'success'
            })
            this.classone = false
          } else {
            this.$notify({
              title: '失败',
              message: resp.data.msg,
              type: 'error'
            })
            this.classone = false
          }
        })
      },
      findDutyOrGroup (classId, type) {
        getGroupList({ pageNum: 1, pageSize: 100, classId: classId, type: type }).then(res => {
          this.selectDutyOrGroup = res.data.data.rows
        })
      }
    }
  }
</script>

<style scoped>
.dash-board {display: flex; align-items: center; background-color: white; height: 60px; padding: 10px 0;}
.bg{margin-top: 15px; padding: 20px; background-color: white;}
  .a{
    position: absolute;
    /*top: 10px;*/
    left: 15px;
    line-height: 40px;
  }
  .b{
    position: absolute;
    top: 15px;
    left: 460px;
  }
  .c{
    position: absolute;
    left: 70%;
    top: 5px;
  }
  .d{
    position: absolute;
    left: 90%;
    top: 15px;
  }
  .spani{color: #2c2fd6}
  .ming{
    position: relative;
    left: -150px;
  }
  .minginp{
    position: relative;
    top: -45px;
  }
  .tianjia{position: absolute;left: 585px;top: 15px;}
  .tianjiaTwo{position: absolute;left: 720px;top: 15px;}
  .cheng{position: relative;left: -140px}
  .xiala{position: relative;top: -40px}
  .dutyss{width: 300px;position: relative;top: -70px; left: 150px}
  .dutysss{width: 300px;position: relative;top: 35px;left: -150px}
  .dutyto{position: absolute; left: 100px; top: 65px}
  .dutytp{position: absolute;left: 100px;top: 140px}
  .yessd{position: relative;left: 220px}
  .NamE{position: relative; top: -8px;left: 20px;height: 30px; line-height: 30px; width: 22%;overflow: hidden;  /** 隐藏超出的内容 **/
       word-break: break-all;
       text-overflow: ellipsis; /** 多行 **/
       display: -webkit-box; /** 对象作为伸缩盒子模型显示 **/
       -webkit-box-orient: vertical; /** 设置或检索伸缩盒对象的子元素的排列方式 **/
       -webkit-line-clamp: 1; /** 显示的行数 **/
}
</style>
